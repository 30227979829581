export enum USER_TYPE {
  employer = "Employer",
  candidate = "Candidate",
}

export enum NOTIFICATION_TYPE {
  job_expiring = "Job_Expiring",
  job_expired = "Job_Expired",
  new_message = "New_Message",
  new_job = "New_Job",
  new_application = "New_Application",
  invite_accepted = "Invite_Accepted",
  application_shortlisted = "Application_Shortlisted",
  application_declined = "Application_Declined",
  application_applied = "Application_Applied",
  application_invited = "Application_Invited",
  application_removed = "Application_Removed",
  application_hired = "Application_Hired",
  video_reviewed = "Video_Reviewed",
}

export enum ACTIVITY_LOG_CATEGORY {
  job = "Job",
  application = "Application",
  company = "Company",
  team = "Team",
  chat = "Chat",
}

export enum ACTIVITY_LOG_ACTION {
  created = "Created",
  updated = "Updated",
  deleted = "Deleted",
  archived = "Archived",
  reposted = "Reposted",
}

export enum VERIFICATION_LEVEL {
  disputed = "Disputed",
  reference_verified = "ReferenceVerified",
  internally_verified = "InternallyVerified",
  unverified = "Unverified",
}

export enum OAUTH_METHOD  {
  login = "login",
  register = "register",
};

export enum MESSAGE_TYPE {
  system = "System",
  user = "User",
}

export const APPLICATION_STATUS = {
  applied: "Applied",
  shortlisted: "Shortlisted",
  declined: "Declined",
  invited: "Invited",
  hired: "Hired",
};

export const ARCHIVE_TYPE = {
  archived: "Archived",
  filled: "Filled",
};

export const TEAM_ROLE = {
  owner: "Owner",
  admin: "Admin",
  user: "User",
};

export const SUPABASE_BUCKETS = {
  profileImages: "profile_images",
  companyLogos: "company_logos",
};

